.profile_info_box {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile_box {
  margin-top: 120px;
  display: flex;
  gap: 60px;
}

.profile_menu {
  display: flex;
  flex-direction: column;
}

.profile_menu_item {
  width: 320px;
  height: 64px;
}

.profile_menu_footer {
  width: 320px;
  height: 48px;
  color: #8585ad;
}

.profile_button_box {
  display: flex;
  justify-content: center;
}
