.burger_constructor {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: 40vh;
}

.burger_item {
  display: flex;
  align-items: center;
}
.order {
  padding-top: 40px;
  display: flex;
  gap: 40px;
  justify-content: flex-end;
}

.order_price {
  align-self: center;
}

.content_box {
  width: 600px;
}

.drop_box {
}
