.IngredientDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IngredientDetails_table {
    color: #8585ad;
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
}

.IngredientDetails img {
    width: 520px;
    height: 240px;
}

.IngredientDetails_form {
    height: 439px;
    margin-top: 40px;

    margin-bottom: 60px;
    text-align: center;
}