body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-height: 100vh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    text-decoration: none;
    color: #4c4cff;
}

.center {
    padding-left: calc(50% - 620px);
    padding-right: calc(50% - 620px);
    display: flex;
    justify-content: center;
}

#scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: #2f2f37;
}

#scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: #2f2f37;
}

#scrollbar::-webkit-scrollbar-thumb {
    background-color: #8585ad;
}

.active {
    color: #f2f2f3;
}

.inactive {
    color: #8585ad;
}