.order_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 478px;
  margin-top: 120px;
  margin-bottom: 120px;
}

.order_image {
  width: 120px;
  height: 120px;
}

.order_grey {
  color: #8585ad;
}
