.element {
    width: 584px;
    background-color: #1C1C21;
    border-radius: 40px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.border {
    width: 64px;
    height: 64px;
    text-align: center;
    border: 2px solid #801AB2;
    border-radius: 100%;
    position: relative;
}

.ingredients_box {
    display: flex;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summ {
    display: flex;
    gap: 10px;
}

.date {
    color: #8585AD;
}

.link {
    color: #ffffff;
}

.span {
    position: absolute;
    top: 30%;
    left: 30%;
}