.modal {
  top: 20%;
  left: 30%;
  position: fixed;
  background-color: #1c1c21;
  z-index: 100;
  width: 720px;
  display: flex;
  flex-direction: column;
  border-radius: 40px;
}
.modal_bg:before {
  content: "";
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 60%;
  z-index: 99;
}

.modal_button {
  align-self: end;
  background: none;
  border: none;
  position: absolute;
}
