.content_box {
  width: 600px;
  height: 912px;
}

.content_box_header {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

.content_box_tab {
  margin-bottom: 40px;
}

.content_box_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.content_box_ingredients {
  overflow: auto;
  height: 60vh;
}

.tabs_component {
  display: flex;
  justify-content: center;
}
