.app__header {
    height: 88px;
    display: flex;
    background-color: #1c1c21;
}

.app__header_button {
    display: flex;
    gap: 8px;
    align-items: center;
}


.app__header_button_grey {
    color: #8585ad;
}

.app__header_logo {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.app__header_right {
    margin-left: auto;
}

.active {
    color: aqua;
}