.ingredient {
  width: 272px;
  height: 208px;
}

.ingredient_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  position: relative;
}

.ingredient_link {
  color: #ffffff;
}
.ingredient_name {
  text-align: center;
}
