.app {
  background-color: #131316;
}

.center {
  padding-left: calc(50% - 960px);
  padding-right: calc(50% - 960px);
}
.content {
  display: flex;
  justify-content: center;
  gap: 40px;
}
