.login_box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 128px;
  align-items: center;
}

.login_button {
  width: 128px;
}

.login_text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  align-items: center;
}
